import React from 'react'
import styled, { css } from 'styled-components'
import Swiper from 'react-id-swiper'

import { media } from '../../../styles/utils'
import {
    container,
    bgIcon,
    padding,
    hoverState,
    bgImage,
    colours,
} from '../../../styles/global'
import 'swiper/css/swiper.css'

const BlockSliderNews = (props) => {
    const { news } = props

    const swiperParams = {
        slidesPerView: 1,
        spaceBetween: 40,
        grabCursor: true,
        loop: true,
        autoplay: {
            delay: 3000,
        },
        pagination: {
            el: '.custom-pagination',
            type: 'bullets',
            clickable: true,
        },
    }

    const renderItems = (props) => {
        if (!news) return

        return news.map((item, i) => {
            return (
                <Slide key={i}>
                    <Date>{item.date}</Date>
                    <Text>{item.text}</Text>
                </Slide>
            )
        })
    }

    return (
        <>
            <Swiper {...swiperParams}>{renderItems()}</Swiper>
        </>
    )
}

// Shared

const Date = styled.div``
const Text = styled.div``

// Layout

BlockSliderNews.wrapper = css`
    .custom-pagination {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 40;

        ${media.tablet`
			position: relative;
			text-align: center;
		`}

        .swiper-pagination-bullet {
            border: 1px solid ${colours.offblack};
            background: transparent;
            width: 13px;
            height: 13px;
            margin: 0 16px 0 0;
            opacity: 1;
            cursor: pointer;

            &:focus {
                outline: none;
            }

            &:last-child {
                margin-right: 0;
            }

            &.swiper-pagination-bullet-active {
                background: ${colours.offblack};
            }
        }
    }
`

// Slider

const Slide = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;

    ${media.tablet`
		padding: 0 20px 20px;
	`}

	${Text} {
		color: ${colours.offblack};
		letter-spacing: 0.5px;
	}

    ${Date} {
		color: ${colours.offblack};
		letter-spacing: 0.5px;
		
        &:after {
            content: '—';
            display: inline-block;
            padding: 0 12px;
        }
    }
`

export default BlockSliderNews
