import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { motion, AnimatePresence } from 'framer-motion'

import { media } from '../../../styles/utils'
import {
    container,
    bgIcon,
    padding,
    noPadding,
    hoverState,
    bgImage,
    button,
    colours,
} from '../../../styles/global'

import blankImage from '../../../assets/images/menu-graphic.svg'

const BlockMenu = (props) => {
    const { text, menus, date } = props
    const [activeIndex, setActiveIndex] = useState(-1)
    const [isHovered, setHovered] = useState(false)

    // Events

    const handleHover = (boolean, index) => {
        setActiveIndex(index)
        setHovered(boolean)
    }

    // Menu Links

    const renderMenuItems = (props) => {
        if (!menus) return

        const items = menus.map((item, i) => {
            return (
                <Item
                    key={i}
                    as={'a'}
                    href={item.pdf}
                    target={'_blank'}
                    onMouseEnter={() => {
                        handleHover(true, i.toString())
                    }}
                    onMouseLeave={() => {
                        handleHover(false, -1)
                    }}
                >
                    {item.button_text}
                </Item>
            )
        })
        return <Menu>{items}</Menu>
    }

    // Menu Images

    const renderMenuImages = (props) => {
        if (!menus) return

        const items = menus.map((item, index) => {
            return (
                <>
                    <AnimatePresence>
                        {item.preview_image && activeIndex == index && (
                            <MenuImage
                                src={item.preview_image.sizes.large}
                                key={index}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.1 }}
                            />
                        )}
                    </AnimatePresence>
                </>
            )
        })
        return (
            <Images>
                {items}
                <Image src={blankImage}>
                    {(src, loading) => {
                        return (
                            <BlankImage
                                src={src}
                                style={{ opacity: loading ? 0 : 1 }}
                            />
                        )
                    }}
                </Image>
            </Images>
        )
    }

    return (
        <>
            <Container>
                <Meta>
                    <Text dangerouslySetInnerHTML={{ __html: text }} />

                    {renderMenuItems()}

                    <Date
                        dangerouslySetInnerHTML={{
                            __html: 'Last Updated ' + date,
                        }}
                    />
                </Meta>

                {renderMenuImages()}
            </Container>
        </>
    )
}

// Shared

const Date = styled.div``
const Text = styled.div``
const Menu = styled.div``
const Item = styled.div``

// Base

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
`

const MenuImage = styled(motion.img)``
const BlankImage = styled.img``

// Layout

BlockMenu.wrapper = css`
    height: 100%;
`

const Container = styled.div`
    display: flex;
    min-height: 100vh;

    ${media.phone`
		flex-direction: column;
		min-height: 100%;
	`}
`

// Meta

const Meta = styled.div`
    width: 50%;
    padding: 40px;
    background-color: ${colours.grey};
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${media.tablet`
		width: 100%;
		padding: 100px 100px 50px;
	`}

    ${media.phone`
		padding: 100px 20px 50px;
	`}

    ${Text} {
        font-family: 'Basic Commercial', sans-serif;
        font-size: 38px;
        line-height: 45px;
        padding-right: 40px;

        ${media.tablet`
			font-size: 29px;
			line-height: 32px;
			text-align: center;
			padding: 0 0 100px 0;
	`}
    }

    ${Menu} {
        display: flex;
        flex-direction: column;

        ${media.tablet`
			padding: 0 0 100px 0;
			align-items: center;
		`}

        ${Item} {
            ${button}
            min-width: 100%;
            margin-bottom: 30px;
            color: ${colours.black};

            &:last-child {
                margin: 0;
            }

            ${media.tablet`
				min-width: auto;
				width: 100%;
			`}

            ${media.phone`
				min-width: 100%;
			`}

            &:hover {
                background: ${colours.black};
                color: ${colours.white};
                cursor: pointer;
            }
        }
    }

    ${Date} {
        text-transform: uppercase;
        color: ${colours.grey};

        ${media.phone`
			text-align: center;
		`};
    }
`

// Images

const Images = styled.div`
    width: 50%;
    padding: 100px 0;
    background-color: ${colours.black};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${media.tablet`
		display: none;
	`}

    ${MenuImage} {
        max-width: 500px;
        position: absolute;
    }

    ${BlankImage} {
        max-width: 500px;
    }
`

export default BlockMenu
