import React, { useState, useRef } from 'react'
import { navigate } from 'gatsby'
import { useWindowScroll, useLocation } from 'react-use'
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import * as Scroll from 'react-scroll'
import {
    Link,
    Element,
    animateScroll as scroll,
    scrollSpy,
    scroller,
} from 'react-scroll'
import { isTabletOnly } from 'react-device-detect'

import { media } from '../../styles/utils'
import { container, bgIcon, padding, colours } from '../../styles/global'
import { getGlobalData } from '../../utils'

const Header = (props) => {
    const { data } = getGlobalData('')
    const [open, setOpen] = useState(false)
    const location = useLocation()

    // Scroll Easing

    const handleClick = (e, to) => {
        e.preventDefault()
        e.stopPropagation()

        const performScroll = () => {
            const scrollToElement = () => {
                const element = document.getElementById(to);
                if (element) {
                    scroller.scrollTo(to, {
                        duration: 1500,
                        smooth: 'easeInOutCubic',
                    });
                } else {
                    setTimeout(scrollToElement, 100);
                }
            };
            scrollToElement();
        }

        const isFunctionsPage = location.pathname === '/functions/' || location.pathname === '/functions';
        console.log(`Current path: ${location.pathname}`)

        if (isFunctionsPage) {
            navigate('/').then(() => {
                console.log('Navigation complete, waiting before scroll');
                setTimeout(() => {
                    console.log('Attempting scroll after navigation');
                    performScroll();
                }, 1000);
            });
        } else {
            performScroll()
        }
    
        setOpen(!open)
    }

    // Nav Items

    const renderNavItems = (items) => {
        return navItems.map((item, i) => {
            return (
                <>
                    {!item.type && (
                        <Link
                            to={item.id}
                            spy={true}
                            duration={500}
                            className="nav-item"
                            activeClass="active"
                            onClick={(e) => handleClick(e, item.id)}
                        >
                            {item.title}
                        </Link>
                    )}

                {item.type == 'pdf' && (
                        <ExternalLink
                            href={data?.winetastings_pdf ? data?.winetastings_pdf : '#'}
                            className="nav-item"
                            activeClass="active"
                            target="_blank"
                        >
                            {item.title}
                        </ExternalLink>
                    )}

                {item.type == 'page-link' && (
                    <ExternalLink
                        href={item.url}
                        className="nav-item"
                        activeClass="active"
                    >
                        {item.title}
                    </ExternalLink>
                )}
                </>
            )
        })
    }

    return (
        <>
            <Container {...variants}>
                <Link
                    href="home"
                    spy={true}
                    duration={500}
                    className="logo-item"
                    activeClass="active"
                    onClick={(e) => {
                        if (window.location.pathname !== '/') {
                            navigate('/')
                        }
                        handleClick(e, 'home')
                    }}
                >
                    Essie Wine
                </Link>
                
                <Hamburger
                    active={open}
                    onClick={() => {
                        setOpen(!open)
                    }}
                >
                    <Line />
                    <Line />
                    <Line />
                </Hamburger>
                <Menu active={open}>{renderNavItems()}</Menu>
            </Container>
        </>
    )
}

// Shared

const Text = styled.div``
const Wrapper = styled.div``
const Line = styled.div``
const ExternalLink = styled.a``

// Layout

const Container = styled(motion.div)`
    ${padding}
    padding-top: 40px;
    padding-bottom: 40px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-family: 'Basic Commercial', sans-serif;
    z-index: 400;
    color: ${colours.offblack};

    ${media.tablet`
		padding-top: 28px;
		padding-bottom: 28px;
	`}

    .logo-item {
        z-index: 200;
		letter-spacing: 0.5px;
        color: ${colours.offblack} !important;

        &:hover {
            border-bottom: 1px solid;
            padding-bottom: 3px;
            cursor: pointer;
        }

        ${media.tablet`
			font-size: 29px;
		`}
    }
`

// Hamburger

const Hamburger = styled.div`
    position: absolute;
    top: 4px;
    right: 0px;
    padding: 20px;
    box-sizing: border-box;
    width: 72px;
    height: 72px;
    border: none;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    z-index: 200;
    display: none;
	transition: all 0.3s linear;

    ${media.tablet`
		display: flex;
	`}

	/*${(props) => {
		if (props.active) return css`
			height: 80px;
		`
    }}*/

    ${Line} {
        width: 32px;
        height: 1px;
        background: ${colours.offblack};
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 1px;

        &:first-child {
            transform: ${(props) =>
                props.active ? 'rotate(45deg)' : 'rotate(0)'};
        }

        &:nth-child(2) {
            opacity: ${(props) => (props.active ? '0' : '1')};
        }

        &:nth-child(3) {
            transform: ${(props) =>
                props.active ? 'rotate(-45deg)' : 'rotate(0)'};
        }
    }
`

// Menu

const Menu = styled.div`
    display: flex;
    box-sizing: border-box;

    ${media.tablet`
		position: fixed;
		left: 0;
		top: 0;
		flex-direction: column;
		justify-content: center;
		background: ${colours.pink};
		width: 100%;
		height: fill-available;
		z-index: 100;
		padding: 20px;
		transition: transform 0.8s ease;
		transform: ${(props) =>
            props.active ? 'translateX(0%)' : 'translateX(-100%)'};
	`}

    .nav-item {
        margin-left: 35px;
        width: fit-content;
		letter-spacing: 0.5px;
        color: inherit;

        ${media.tablet`
			font-size: 29px;
			line-height: 35px;
			margin: 0;
		`}

        &.active {
            border-bottom: 1px solid;
            padding-bottom: 3px;

			${media.tablet`
				border: 0;
				padding: 0;
			`}

            &:hover {
                border-bottom: none;
                cursor: pointer;
            }
        }

        &:hover {
            border-bottom: 1px solid;
            padding-bottom: 3px;
            cursor: pointer;

			${media.tablet`
				border: 0;
				padding: 0;
			`}
        }
    }
`

// Nav

const navItems = [
    {
        title: 'About',
        id: 'about',
    },
    {
        title: 'Wine Tastings',
        type: 'pdf'
    },
    {
        title: 'Menu',
        id: 'menu',
    },
    {
        title: 'Bookings',
        id: 'bookings',
    },
    {
        title: 'Functions',
        url: '/functions',
        type: 'page-link'
    },
    {
        title: 'Private Dining',
        id: 'dining',
    },
    {
        title: 'Vouchers',
        id: 'vouchers',
    },
    {
        title: 'Contact',
        id: 'contact',
    },
]

// Animation
const variants = {
	initial: {
		opacity: 0,
	},
	animate: {
		opacity: 1,
		transition: {
			duration: 0.8,
			delay: 0.8,
		},
	}
}

export default Header
