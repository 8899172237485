import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'

import { Block } from '../../../components'
import { media } from '../../../styles/utils'
import {
    container,
    bgIcon,
    padding,
    hoverState,
    bgImage,
    type,
} from '../../../styles/global'

const BlockGrid = (props) => {
    const { grid } = props

    const renderGrid = (props) => {
        if (!grid) return

        const rows = grid.map((item, i) => {
            return renderItems(item.items)
        })

        return <Grid>{rows}</Grid>
    }

    const renderItems = (items) => {
        if (!items) return

        return items.map((block, i) => {
            return (
                <Block
                    layout={'image'}
                    display
                    inline={false}
                    image={block.image}
                    caption={block.caption}
                    key={i}
                />
            )
        })
    }

    return <>{renderGrid()}</>
}

const Grid = styled.div`
    display: flex;
    flex-wrap: wrap;

    > * {
        /* 1 */
        &:nth-child(6n + 1) {
            width: 70%;
            margin-left: auto;
            flex-direction: column;
            align-items: left;

            .image {
                width: 100%;
                min-height: 660px;
            }
        }
        /* 2 */
        &:nth-child(6n + 2) {
            width: 40%;
            margin-right: auto;
            flex-direction: column;
            justify-content: flex-start;
            align-items: left;

            .image {
                width: 100%;
                min-height: 600px;
            }
        }
        /* 3 */
        &:nth-child(6n + 3) {
            width: 50%;
            margin-left: auto;
            flex-direction: column;
            align-items: left;
            padding-top: 300px;

            .image {
                width: 100%;
                min-height: 990px;
            }
        }

        /* 4 */
        &:nth-child(6n + 4) {
            width: 70%;
            margin-right: auto;
            flex-direction: column;
            align-items: left;

            .image {
                width: 100%;
                min-height: 660px;
            }
        }
        /* 5 */
        &:nth-child(6n + 5) {
            width: 50%;
            margin-right: auto;
            flex-direction: column;
            align-items: left;
            padding-top: 300px;

            .image {
                width: 100%;
                min-height: 990px;
            }
        }
        /* 6 */
        &:nth-child(6n + 6) {
            width: 40%;
            margin-right: auto;
            flex-direction: column;
            justify-content: flex-start;
            align-items: left;

            .image {
                width: 100%;
                min-height: 600px;
            }
        }

        /* Responsive */
        &:nth-child(6n + 1),
        &:nth-child(6n + 4) {
            ${media.phone`
				width: 100%;

				.image {
					min-height: 216px;
				}
			`}
        }
        &:nth-child(6n + 2),
        &:nth-child(6n + 6) {
            ${media.phone`
				width: 100%;

				.image {
					min-height: 326px;
				}
			`}
        }
        &:nth-child(6n + 3),
        &:nth-child(6n + 5) {
            ${media.phone`
				width: 100%;
				padding-top: 0px;

				.image {
					min-height: 439px;
				}
			`}
        }
    }
`

export default BlockGrid
