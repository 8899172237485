import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import {
    container,
    bgIcon,
    padding,
    hoverState,
    bgImage,
    type,
} from '../../../styles/global'

const BlockText = (props) => {
    const { paragraph, serif, padding } = props

    return (
        <Text
            className={'text'}
            padding={padding}
            serif={serif}
            dangerouslySetInnerHTML={{ __html: paragraph }}
        />
    )
}

// Wrapper

BlockText.wrapper = css``

// Layout

const Text = styled.div`
    max-width: 80%;
    margin: 0 auto;
    padding: 285px 0;

    ${media.phone`
		max-width: 100%;
		padding: 100px 0px;
	`}

    ${(props) => {
        if (props.padding == 'small')
            return css`
                padding: 150px 0;

                ${media.phone`
					padding: 100px 20px;
				`}
            `
        if (props.padding)
            return css`
                padding: ${(props) => props.padding};
            `
    }}

  p {
        ${type.heading2};
        font-family: 'Basic Commercial', sans-serif;
        text-align: center;
        margin: 0;

        ${media.tablet`
            font-size: 27px;
            line-height: 1.2;
        `}

        ${(props) => {
            if (props.serif)
                return css`
                    font-family: 'Adobe Caslon', sans-serif;
                `
        }}
    }
`

export default BlockText
