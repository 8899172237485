import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'

import { media } from '../../../styles/utils'
import {
    container,
    bgIcon,
    padding,
    hoverState,
    bgImage,
    type,
} from '../../../styles/global'

const BlockImage = (props) => {
	const { image, styles, inline, caption } = props

    return (
        <>
            {inline && (
                <Image key={image.url} src={image.url}>
                    {(src, loading) => {
                        return (
                            <InlineImage
                                src={src}
                                styles={styles}
                                style={{ opacity: loading ? 0 : 1 }}
                            />
                        )
                    }}
                </Image>
            )}

            {!inline && (
                <Image key={image.sizes.medium2} src={image.sizes.medium2}>
                    {(src, loading) => {
                        return (
                            <BGImage
                                className={'image'}
                                image={src}
                                styles={styles}
                                style={{ opacity: loading ? 0 : 1 }}
                            />
                        )
                    }}
                </Image>
            )}

            {caption && <Caption className={'caption'}>{caption}</Caption>}
        </>
    )
}

// Wrapper

BlockImage.wrapper = css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 150px;

    ${media.phone`
		padding-bottom: 100px;
	`}
`

// Layout

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const InlineImage = styled.img`
    ${(props) => {
        if (props.styles) return props.styles
    }}
`

const BGImage = styled.div`
    ${bgImage};
    background-image: url(${(props) => props.image});
    transition: opacity 0.45s ease;

    ${(props) => {
        if (props.styles) return props.styles
    }}
`

const Caption = styled.div`
    padding-top: 22px;
    text-align: left;
    width: 100%;
`

export default BlockImage
