import React from 'react'
import styled, { css } from 'styled-components'
import Swiper from 'react-id-swiper'
import ProgressiveImage from 'react-progressive-image'

import { media } from '../../../styles/utils'
import {
    container,
    bgIcon,
    padding,
    hoverState,
    bgImage,
    colours,
} from '../../../styles/global'
import 'swiper/css/swiper.css'

const BlockSlider = (props) => {
    const { slides, inlineCaption } = props

    const swiperParams = {
        slidesPerView: 1,
        spaceBetween: 0,
        grabCursor: false,
        loop: false,
        pagination: {
            el: '.custom-pagination',
            clickable: true,
            renderBullet: function (index, className) {
                return (
                    '<span class="' + className + '">' + (index + 1) + '</span>'
                )
            },
        },
    }

    const renderSlides = (props) => {
        if (!slides) return

        return slides.map((item, i) => {
            return (
                <Slide key={i}>
                    <Caption>
                        {item?.use_caption && (
                            <Text
                                dangerouslySetInnerHTML={{
                                    __html: item?.caption,
                                }}
                            />
                        )}

                        {inlineCaption && (
                            <div className='custom-pagination'>{i + 1}</div>
                        )}
                    </Caption>
                    <Image
                        key={item?.image?.sizes?.medium2}
                        src={item?.image?.sizes?.medium2}
                    >
                        {(src, loading) => {
                            return (
                                <BGImage
                                    image={src}
                                    style={{ opacity: loading ? 0 : 1 }}
                                />
                            )
                        }}
                    </Image>
                </Slide>
            )
        })
    }

    return (
        <>
            <Container inline={inlineCaption}>
                <Swiper {...swiperParams}>{renderSlides()}</Swiper>
            </Container>
        </>
    )
}

// Shared

const Date = styled.div``
const Text = styled.div``

// Base

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
`

// Layout

BlockSlider.wrapper = css`
    max-width: 990px;
    margin: 0 auto;

    ${media.tablet`
		padding: 0 20px;
	`}

    .custom-pagination {
        padding-top: 30px;

        ${media.phone`
			text-align: left;
			padding-top: 22px;
		`}

        .swiper-pagination-bullet {
            background: none;
            width: auto;
            height: auto;
            margin: 0 12px 0 0;
            opacity: 1;
            cursor: pointer;
            border-radius: 0;

            &:last-child {
                margin-right: 0;
            }

            &.swiper-pagination-bullet-active {
                background: none;
                border-bottom: 1px solid black;
                cursor: pointer;
            }
        }
    }
`

const Container = styled.div`
    ${(props) => props.inline && css`

        .swiper-container .custom-pagination {
            display: none;
        }

        .swiper-slide {
            flex-direction: column-reverse;
        }

        ${Caption}{
            position: relative;
            background: none;
            opacity: 1 !important;
            padding: 20px 0px !important;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between !important;

            ${media.phone`
                padding: 12px;
            `}

            ${Text} {
                text-align: left;
                padding-left: 0;
                padding: 0;
                text-transform: uppercase;
            }

            .custom-pagination {
                padding-top: 0 !important;
                display: block !important;
                min-width: fit-content;
            }
        }
    `}
`

// Slider

const Caption = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${colours.yellow};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;

    ${Text} {
        width: 100%;
        max-width: 630px;
        padding: 20px;
        box-sizing: border-box;

        ${media.phone`
            padding: 12px;
        `}

        p {
            margin: 0;

            ${media.phone`
                text-align: left;
            `}
        }
    }
`

const Slide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    position: relative;

    &:hover {
        ${Caption} {
            opacity: 1;
        }
    }

    ${BGImage} {
        width: 100%;
        min-height: 660px;

        ${media.phone`
			min-height: 216px;
		`}
    }
`

export default BlockSlider
