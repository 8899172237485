import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import {
    container,
    bgIcon,
    padding,
    hoverState,
    bgImage,
    type,
    button,
} from '../../../styles/global'

const BlockButton = (props) => {
	const { link, text, removePadding } = props

    console.log('BlockButton props', props)

    return (
        <Button as={'a'} href={link} target="_blank" removePadding={removePadding}>
            {text}
        </Button>
    )
}

// Wrapper

BlockButton.wrapper = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 150px;
	color: black;

    ${media.phone`
		padding-bottom: 100px;
	`}

    ${props => {
        if (props.removePadding) return css`
            padding-bottom: 20px;

            ${media.phone`
                padding-bottom: 12px;
            `}
        `
    }}
`

// Layout

const Button = styled.a`
    ${button}
	color: black;
`

export default BlockButton
