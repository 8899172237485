import {
    BlockText,
    BlockButton,
    BlockImage,
    BlockMenu,
    BlockSlider,
    BlockSliderNews,
    BlockGrid,
} from './templates'

export const resolveBlock = (layout) => {
    let block

    // Map layout strings to blocks

    const blocks = {
        text: BlockText,
        button: BlockButton,
        image: BlockImage,
        menu: BlockMenu,
        slider: BlockSlider,
        slider_news: BlockSliderNews,
        grid: BlockGrid,
    }

    return blocks[layout]
}
