import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import styled, { css } from 'styled-components'
import Transition from '../../transition'

import { MetaTags, Header, Footer } from '../'
import { media } from '../../styles/utils'

const Layout = ({ children, meta, footer }) => {
  return (
    <>
      <MetaTags {...meta} />

      <Header />

      <Wrapper>
        <Transition>{children}</Transition>
      </Wrapper>

      <Footer paragraph={footer} />
    </>
  )
}

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
`

export default Layout
