import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import { type, colours } from '../../../styles/global'
import { renderError, inputBase } from '../shared'

const FieldInput = (props) => {
	const [focused, setFocused] = useState(false)

	const getInputProps = () => {
		return {
			onChange: (e) => props.onChange(e.target?.value),
			onFocus: (e) => setFocused(true),
			onBlur: (e) => setFocused(false),
		}
	}

	return (
		<Wrapper>
			<Input 
				{...props}
				{...getInputProps()}
			/>

            {/*{renderError(props, focused)}*/}
		</Wrapper>
	)
}

// Layout

const Wrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	min-width: 374px;

	${media.phone`
		min-width: 284px;
	`}
	
		input[type=number]::-webkit-inner-spin-button, 
		input[type=number]::-webkit-outer-spin-button {  
			opacity: 1;
			-webkit-appearance: none; 
		}
`

// Input

export const inputStyle = css`
   	${inputBase}
   	transition: all 0.25s ease;
    background: none;
    border-bottom: 1px solid ${colours.black};
    text-align: center;
    text-transform: uppercase;
    font-family: 'Elementa', sans-serif;
    ${type.body};

    &, &::placeholder {
        /* ${type.heading3} */
    }

    &::placeholder {
        color: ${colours.black};
    }

    &:focus,
    &:hover {
        outline: none;
    }

	&:focus::placeholder {
		color: transparent;
	}

    ${props => {
        if (props.shouldValidate && !props.valid) return css`
            border-bottom: 1px solid ${colours.error};
        `
	}}

`

const Input = styled.input`
    ${inputStyle}
`

FieldInput.wrapper = css``
FieldInput.displayName = 'FieldInput'

export default FieldInput
