import React, { useState } from 'react'
import { navigate, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'

import { Block, Newsletter } from '../../components'
import { media } from '../../styles/utils'
import {
    container,
    bgIcon,
    padding,
    hoverState,
    colours,
} from '../../styles/global'
import svgImage from '../../assets/images/footer-graphic.svg'

const Footer = (props) => {
    const { paragraph } = props

    return (
        <>
            <Container id="contact">
                <Meta>
					<Text dangerouslySetInnerHTML={{ __html: paragraph }} />
					<Newsletter />
                </Meta>
                <Wrapper>
                    <Image src={svgImage}>
                        {(src) => {
                            return <InlineImage src={src} />
                        }}
					</Image>
                    <Credits>
                        <Text>
                            Illustration:
                            <a
                                href="https://www.lennardkok.com/"
                                target="_blank"
                            >
                                Lennard Kok
                            </a>
                        </Text>
                        <Text>
                            Design:
                            <a
                                href="https://www.camnorris.xyz/"
                                target="_blank"
                            >
                                Cam Norris
                            </a>
                        </Text>
                        <Text>
                            Development:
                            <a
                                href="https://www.morebymore.com/"
                                target="_blank"
                            >
                                More Studio
                            </a>
                        </Text>
                    </Credits>
                </Wrapper>
            </Container>
        </>
    )
}

// Shared

const Text = styled.div``
const Image = styled(ProgressiveImage)``
const InlineImage = styled.img``
const Credits = styled.div``

// Layout

const Container = styled.div`
    background: ${colours.pink};
    ${padding}
    padding-top: 100px;
    padding-bottom: 50px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${media.phone`
		min-height: 100%;
	`}
`

const Meta = styled.div`
    ${media.phone`
		padding-bottom: 200px;
	`}

    ${Text} {
        text-align: center;
        font-family: 'Basic Commercial', sans-serif;
        font-size: 38px;
        line-height: 45px;

        p {
            font-size: 38px;
            line-height: 45px;
            color: ${colours.offblack};
            margin: 0;
            padding-top: 20px;

            ${media.phone`
				font-size: 29px;
				line-height: 32px;
			`}

            a {
                font-size: 38px;
                line-height: 45px;
                color: inherit;
                border-bottom: 1px solid;

                ${media.phone`
					font-size: 29px;
					line-height: 32px;
				`}

                &:hover {
                    border: 0;
                }
            }

			span {
				${media.phone`
					display: none;
				`}
			}
        }
    }
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
	align-items: center;

    ${InlineImage} {
        max-width: 264px;
        margin: 0 auto;
        padding-bottom: 40px;

        ${media.phone`
			padding-bottom: 50px;
		`}
    }

    ${Credits} {
        display: flex;
        justify-content: center;
        text-transform: uppercase;
        text-align: center;

        ${media.tablet`
			flex-direction: column;
		`}

        ${Text} {
            margin-left: 35px;
            letter-spacing: 0.8px;
            color: ${colours.offblack};

            ${media.tablet`
				margin-left: 0;
				margin-bottom: 8px;
			`}

            ${media.phone`
				margin-bottom: 0;
			`}

            a {
                border-bottom: 1px solid #344b52;
                color: ${colours.offblack};
                margin-left: 12px;
				padding-bottom: 2px;

                ${media.tablet`
					margin-left: 0;
				`}

                &:hover {
                    border: 0;
                }
            }
        }
    }
`

export default Footer
