import React from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../styles/utils'
import { container, bgIcon, padding, hoverState } from '../../styles/global'
import { resolveBlock } from './utils'

const Block = (props) => {
    const {
        layout,
        lastBlock,
        block_type,
        inAccordion,
        display,
        serif,
        padding,
        styles,
        additonalStyles,
        removePadding,
    } = props
    const BlockLayout = resolveBlock(layout)

    if (!BlockLayout || !display) return <></>

    return (
        <Wrapper
            className={`block_${layout}`}
            layout={layout}
            blockType={block_type}
            blockProps={props}
            styles={BlockLayout && BlockLayout.wrapper}
            additonalStyles={styles}
            lastBlock={lastBlock}
            inAccordion={inAccordion}
            removePadding={removePadding}
        >
            {BlockLayout && <BlockLayout {...props} />}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100%;

    /* Block specific container styles */

    ${(props) => {
        if (props.styles) return props.styles
    }}
	
	/* Additional passed styles */

	${(props) => {
        if (props.additonalStyles) return props.additonalStyles
    }}
`

export default Block
