import React, { useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import Form, { useForm } from 'react-dynamic-form'
import { useWindowSize } from 'react-use'
import Reward from 'react-rewards';

import { Field } from '../'

import { media } from '../../styles/utils'
import { colours, button } from '../../styles/global'
import { postData } from '../../services/api'
import { validators } from '../../validators'

const DiningForm = (props) => {
    const [form, { fieldState, formSubmit, setError }] = useForm()
	const [formComplete, setFormComplete] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const { width, height } = useWindowSize()

	const reward = useRef();

	const confettiConfig = {
		spread: 400,
		lifetime: 200,
		startVelocity: 90,
		elementSize: 90,
		decay: 0.9,
		zIndex: 0,
		elementCount: 90,
		springAnimation: false,
		emoji: [
			'🍷',
			'🍻',
			'🥂',
			'🍾',
		]
	}

	const fakeSumbit = (fields, resetForm) => { 
		reward.current.rewardMe();

		setTimeout(() => {
			setFormComplete(true)
		}, 2000);
	}

    const handleSubmit = (fields, resetForm) => {
        console.log('handleSubmit - fields', fields)

        const data = {
            name: fields.name.value,
            email: fields.email.value,
            phone: fields.phone.value,
            booking_date: fields.bookingDate.value,
            num_guests: fields.numGuests.value,
            occasion: fields.occasion.value,
        }

        setSubmitting(true)

        postData('/private-dining-form', data).then((res) => {
            if (res?.success == true) {
				setFormComplete(true)
				reward.current.rewardMe();
            } else {
                setError('Server error')
            }
        })
        .finally(() => {
            setSubmitting(false);
        });
    }


        return (
            <Wrapper>
                <Form
                    form={form}
                    data={formFields}
                    styles={formStyles}
                    renderInput={<Field />}
                    renderSubmit={false}
                    onSubmit={handleSubmit}
                    moveToInvalidField={false}
                />
                
                <Reward
                    ref={reward}
                    type='emoji'
                    config={confettiConfig}
                >
                   <Submit onClick={formSubmit} complete={formComplete} disabled={submitting}>
                        {formComplete ? 'Thank you for your request' : (!submitting ? 'Submit Booking Request' : null)}
                        {submitting && 'Loading...'}
                    </Submit>
                </Reward>
                {form.error && <Error>{form.error}</Error>}
            </Wrapper>
        )
    }

// Shared

const Text = styled.div``
const Button = styled.div``

// Layout

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: 80%;
    width: 100%;
	margin: 0 auto;
	overflow: hidden;
    margin-bottom: 150px;

    ${media.phone`
		max-width: 100%;
		padding: 0 20px;
        margin-bottom: 100px;
	`}
`

// Form

const formStyles = css`
    width: 100%;
    margin-bottom: 100px;

    .dynamic-fields {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        width: 100%;

        .dynamic-field {
            flex-basis: calc(50% - 32px);

            ${media.phone`
				flex-basis: 100%;
			`}
        }
    }
`

// Submit

const Submit = styled.div`
    ${button}
	margin-bottom: 20px;
	z-index: 100;
	position: relative;
	user-select: none;

    ${media.phone`
		 width: 100%;
	`}

    ${(props) => {
        if (props.complete)
            return css`
                pointer-events: none;
            `
	}}
	
	&:hover {
		color: ${colours.gold};
	}
`

// Serverside error

export const Error = styled.div`
    color: ${colours.black};
    margin: 16px 0;
`

// Form Fields

const formFields = [
    {
        type: 'text',
        name: 'name',
        label: 'Full name*',
        required: true,
        validator: validators.required,
        errorMessage: 'Please enter a valid name'
    },
    {
        type: 'email',
        name: 'email',
        label: 'Email*',
        required: true,
        validator: validators.required,
        errorMessage: 'Please enter a valid email'
    },
    {
        type: 'tel',
        name: 'phone',
        label: 'Telephone*',
        required: true,
        validator: validators.phone,
        errorMessage: 'Please enter a valid number'
    },
    {
        type: 'text',
        name: 'bookingDate',
        label: 'Booking date*',
        required: true,
        validator: validators.required,
        errorMessage: 'Please enter a valid date'
    },
    {
        type: 'number',
        name: 'numGuests',
        label: 'Guests*',
        required: true,
        validator: validators.required,
        errorMessage: 'Please enter a valid number'
    },
    {
        type: 'text',
        name: 'occasion',
        label: 'Occasion*',
        required: true,
        validator: validators.required,
        errorMessage: 'Please enter an occasion'
    },
]

export default DiningForm
