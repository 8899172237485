import React, { useState, useRef } from 'react'
import styled, { css, ThemeProvider } from 'styled-components'
import { Link } from 'gatsby'
import DynamicForm, { useForm } from 'react-dynamic-form'
import { useSetState } from 'react-use'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import OutsideClickHandler from 'react-outside-click-handler';

import { Field } from '../'

import { media } from '../../styles/utils'
import { container, padding, colours, type } from '../../styles/global'
import { newsletterForm } from './data'

// Todo: hook up to mailchimp and add thankyou/error styling

const Newsletter = (props) => {
    const { heading, text, formStyles } = props
    const [form, { formSubmit, setError }] = useForm()
    const [focus, setFocus] = useState(false)
	const [formComplete, setFormComplete] = useState(false)


    const handleSubmit = (fields, resetForm) => {
        let data = {
            email: fields['email'].value,
        }

        addToMailchimp(data.email, {}).then((mc_resp) => {
            if (mc_resp.result == 'success') {
                setFormComplete(true)
                setError(false)
                console.log('form complete:', formComplete)
            }

            if (mc_resp.result == 'error') {
                setError(mc_resp.msg)
                console.log('form error:', mc_resp.msg)
            }
        })

        resetForm()
    }

    // Form
    const renderForm = () => {
        return (
            <Signup>
                {!formComplete && (
                    <FormWrapper focus={focus}>
                        <DynamicForm
                            form={form}
                            data={newsletterForm}
                            renderInput={<Field />}
                            moveToInvalidField={false}
                            styles={newsletterStyles}
                            renderSubmit={false}
							onSubmit={handleSubmit}
							focus={focus}
                        />

						{focus && (
							<Button theme={'outline-black'} onClick={formSubmit}>
								Submit
							</Button>
						)}
                    </FormWrapper>
                )}

                {form.error && (
                    <Error
                        dangerouslySetInnerHTML={{
                            __html: form.error,
                        }}
                    />
                )}

                {formComplete && (
                    <ThankYou
                        dangerouslySetInnerHTML={{
                            __html: `
							Thanks for joining! 
						`,
                        }}
                    />
                )}
            </Signup>
        )
    }

	return (
		<OutsideClickHandler
			onOutsideClick={() => {
				setFocus(false)
			}}
		>
			<Wrapper onClick={()=> setFocus(true)}>
				<Container>
					{renderForm()}
				</Container>
			</Wrapper>
		</OutsideClickHandler>
    )
}

// Shared

const Subheading = styled.h3``
const Description = styled.div``
const Button = styled.div``

// Layout

const Wrapper = styled.div`
    padding-top: 8px;

    ${media.phone`
		width: 100%;
		padding-bottom: 40px;
	`}
`

const Container = styled.div`
    width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

const Heading = styled.h2`
    padding-bottom: 32px;
    color: ${colours.red};

    ${media.phone`
		padding-bottom: 20px;
	`}
`

// Form

const Signup = styled.div`
    display: flex;
    flex-direction: column;
`

const FormWrapper = styled.div`
    display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.dynamic-fields .dynamic-field input {
		width: 180px;

		${media.phone`
			width: 134px;
		`}

		${(props) => {
			if (props.focus) return css`
				width: 100% !important;
			`
		}}
	}

    ${Button} {
        cursor: pointer;
		width: fit-content;
		height: 48px;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: ${colours.offblack};
		text-transform: uppercase;

        img {
            width: 30px;
        }
    }
`

// Form

const newsletterStyles = css`
    flex-direction: column;
    align-items: flex-start;
    display: flex;
    width: 100%;

    .dynamic-fields {
        width: 100%;
        display: flex;

        ${media.tablet`
			flex-direction: column;
			align-items: center;
			padding-right: 0;
		`}

		.dynamic-field {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			input {
				background: transparent;
				padding: 0;
				text-align: center;
				font-family: 'Basic Commercial', sans-serif;
				font-size: 38px;
				line-height: 45px;
				border-color: ${colours.offblack};
				color: ${colours.offblack};
				height: 48px;
				text-transform: none;
				transform-origin: center;

				${media.phone`
					font-size: 29px;
					line-height: 32px;
					height: 34px;
				`}

				&::placeholder {
					color: ${colours.offblack};
					font-family: 'Basic Commercial', sans-serif;
					font-size: 38px;
					line-height: 45px;
					text-transform: none;
					padding-bottom: 4px;

					${media.phone`
						font-size: 29px;
						line-height: 32px;
					`}
				}

				&:focus::placeholder {
					color: transparent;
				}
			}
		}
    }

    .error {
        margin-top: 32px;

        &,
        p,
        a {
            font-size: 16px;
            color: ${colours.offblack} !important;
        }
    }
`

const ThankYou = styled.div`
    color: ${colours.offblack};
	font-family: 'Basic Commercial', sans-serif;
	font-size: 38px;
	line-height: 45px;
    padding-top: 12px;

	${media.phone`
		font-size: 29px;
		line-height: 32px;
	`}
`

const Error = styled.div`
    font-size: 16px;
    padding-top: 12px;

    a {
        font-size: 16px;
        color: ${colours.red};
    }
`

export default Newsletter
